html { 
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-size: 16px;
 }

.firebase-emulator-warning {
  opacity: 0.10;
  pointer-events: none;
}

.filler {
  background-color: 'transparent';
}

.primary {
  background-color: '#1f91c4' !important;
}

